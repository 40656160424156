import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuth } from '../../features/user/userAuth';

const GuestRoute = ({ Component }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { jwtToken, isOtpVerified ,user} = useSelector(state => state.userAuth);
    const roleId = user.roleId;

    useEffect(() => {
        console.log("User" ,isOtpVerified ,jwtToken ,  roleId);
        
        if (jwtToken && !isOtpVerified) {
            dispatch(clearAuth());
            navigate('/');
            return;
        }
        
        if (isOtpVerified) {
            if(roleId === 2){
                navigate('/guest-dashboard');
            }else if (roleId === 1){
                navigate('/dashboard');
            }else if (roleId === 3){
                navigate('/admin-dashboard');
            }
            return;
        }
    }, [location.pathname]);

    return <Component />;
};

export default GuestRoute;
