import React, { useState } from 'react'
import { Table, Form, Button, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useAxiosWithAuthErrorHandling from '../../AxiosErrorHandling/AxiosErrorHandling';
import { setAuth } from '../../../features/user/userAuth';
import { toast } from "react-toastify";

import customMethods from '../../customMethods';
import LazyLoadImage from '../../LazyLoad/LazyLoad';

const SexOffenderCheckModal = ({ show, onHide, step, setStep, setCustomersData }) => {
    const { jwtToken, isOtpVerified, rememberMe } = useSelector(state => state.userAuth)
    const axios = useAxiosWithAuthErrorHandling();
    const [isSpinnerActive, setisSpinnerActive] = useState(false);
    const [required, setRequired] = useState(false);

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        state: '',
        zipcode: ''
    });

    const [customer, setCustomer] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setRequired(false);
        if (!formData.firstName || !formData.lastName || !formData.state || !formData.zipcode) {
            setRequired(true);
            return;
        }
        setStep(2);
    };

    const handlePaymentConfirmation = async (e) => {
        e.preventDefault();
        try {
            setisSpinnerActive(true);
            const response = await axios({
                method: 'post',
                url: process.env.REACT_APP_API_SEX_OFFENDER,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${jwtToken}`
                },
            });

            if (response.status === 200) {
                setCustomer(response.data);
                setCustomersData(prevState => [
                    { ...response.data },
                    ...prevState
                ]);
                dispatch(setAuth({ jwtToken, isOtpVerified, rememberMe, user: response.data.user }));
                setStep(3);
            } else {
                setStep(0);
            }
            setFormData({
                firstName: '',
                lastName: '',
                state: '',
                zipcode: ''
            });
        } catch (error) {
            setStep(0);
            console.error('Error checking sex offender:', error);
        }
        setisSpinnerActive(false);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='model-custom-dsg'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sex Offender Check
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 && (
                    <Form onSubmit={handleSubmit}>
                        <div className='pop-up-step-one'>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Input First Name" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name='lastName' placeholder="Input Last Name" value={formData.lastName} onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>State</Form.Label>
                                <Form.Control type="text" name='state' placeholder="Input State" value={formData.state} onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Zipcode</Form.Label>
                                <Form.Control type="number" name='zipcode' placeholder="Input Zipcode" value={formData.zipcode} onChange={handleInputChange} />
                            </Form.Group>
                            {required && (
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <small className="text-danger from-input-description">All Fields are Required!</small>
                                </Form.Group>
                            )}
                            <Button variant="primary" type="submit">Submit</Button>
                        </div>
                    </Form>
                )}
                {step === 2 && (
                    <Form>
                        <div className='pop-up-step-two'>
                            <div className='pop-up-step pop-up-step-two'>
                                <LazyLoadImage effect="blur" src={process.env.PUBLIC_URL + '/dollar-icon-pay.svg'} />
                                <div className='pop-step-cntnt'>
                                    <h5>Payment Confirmation</h5>
                                    <p>This is going to be 1 credit ($1.00).</p>
                                    <p>Please confirm to continue</p>
                                </div>
                                <Button variant="primary" onClick={handlePaymentConfirmation} disabled={isSpinnerActive}>
                                    {isSpinnerActive ? <Spinner animation="border" size="sm" /> : null}
                                    &nbsp;
                                    Pay Now
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
                {step === 3 && (
                    <Form>
                        <div className='pop-up-step-three'>
                            <div className='pop-up-step pop-up-step-three'>
                                <LazyLoadImage effect="blur" src={process.env.PUBLIC_URL + '/checklist.svg'} />
                                <div className='pop-step-cntnt'>
                                    <h5>Successful payment</h5>
                                    <p>You have successfully paid, click OK to continue</p>
                                </div>
                                <Button onClick={() => { setStep(4) }} variant="primary">OK</Button>
                            </div>
                        </div>
                    </Form>
                )}
                {step === 0 && (
                    <div className='pop-up-step-three'>
                        <div className='pop-up-step pop-up-step-three'>
                            <LazyLoadImage effect="blur" src={process.env.PUBLIC_URL + '/canellist.svg'} />
                            <div className='pop-step-cntnt'>
                                <h5>Payment Failed</h5>
                                <p>You Don't have enough credits in your account!</p>
                            </div>
                            <Link to="/dashboard/purchase-credits">Add Credits in your account</Link>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div className='pop-up-step pop-up-step-three'>
                        <LazyLoadImage
                            alt='profile-img'
                            effect="blur"
                            width={170}
                            height={200}
                            className='checklist-img-dul rounded'
                            src={customer.status ? process.env.PUBLIC_URL + '/checklist-sm.svg' : customer.offenderImageUrl} />
                                
                        <div className='pop-step-tabelcntnt'>
                            <h6 className='tittle'>Result of Sex Offender Check</h6>
                            <Table responsive="lg" className='popup-table'>
                                {Object.entries(customer).map(([key, value]) => {
                                    if (value !== null && value !== undefined && value !== '' && value !== 'Unknown' && !['','firstNameNickNames', 'content', 'updatedAt','user','offenderImageUrl','userId','createdAt','id','lat','lng'].includes(key)) {
                                        return (
                                        <tr key={key}>
                                            <th> 
                                                {key === 'status' ? 'Status' 
                                                : key === 'crime' ? 'Crime'
                                                : key === 'registrationDate' ? 'Registration'
                                                : key === 'convictionDate' ? 'Conviction Date'
                                                : key === 'offenderUrl' ? 'Offender URL'
                                                : key.charAt(0).toUpperCase() + key.slice(1)}
                                            </th>
                                            <td>
                                                {key === 'status' 
                                                    ? (value ? 'Passed ✅' : 'Failed ❌') 
                                                    : key === 'crime' ? value
                                                    : key === 'offenderUrl' ? value
                                                    : (['dob', 'registrationDate','convictionDate'].includes(key) ? customMethods.formatDate(value) : value)}
                                            </td>
                                        </tr>
                                    )
                                }})}
                            </Table>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default SexOffenderCheckModal;
