import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { clearAuth } from "../../features/user/userAuth";

const AdminRoute = ({ Component }) => {
  const navigate = useNavigate();
  const { user, jwtToken } = useSelector((state) => state.userAuth);
  const roleId = user?.roleId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!jwtToken) {
      navigate("/");
      return;
    }

    try {
      const decodedToken = jwtDecode(jwtToken);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp < currentTime) {
        dispatch(clearAuth());
        navigate("/");
        return;
      }
      if (roleId !== 3) {
        navigate("/dashboard"); // Redirect if user role is not admin
        return;
      }
    } catch (error) {
      dispatch(clearAuth());
      navigate("/");
    }
  }, [jwtToken, roleId, dispatch, navigate]);

  return <Component />;
};

export default AdminRoute;
