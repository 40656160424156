import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../Login/Login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { setAuth } from "../../features/user/userAuth";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import useAxiosWithAuthErrorHandling from "../AxiosErrorHandling/AxiosErrorHandling";

const VerifyEmail = () => {
  const dispatch = useDispatch();

  const axios = useAxiosWithAuthErrorHandling();
  const { user } = useContext(DashboardContext);
  const USER_EMAIL = user?.email;
  const ROLE_ID = user?.roleId;
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [count, setCount] = useState(59);
  const [isActive, setIsActive] = useState(false);
  const [key, setKey] = useState("guest");
  const location = useLocation();
  const navigate = useNavigate();
  const refs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    if (location.state?.userType) {
      const userType = location.state.userType;
      setKey(userType);
    }
  }, [location.state?.userType]);

  useEffect(() => {
    const fetchData = async () => {
      if (otp.every((input) => input !== "")) {
        try {
          let response = await axios({
            method: "post",
            url: process.env.REACT_APP_API_VERIFY_OTP,
            data: {
              email: USER_EMAIL,
              roleId: ROLE_ID,
              otp: otp.join(""),
            },
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });

          if (response.status === 200) {
        

            if (ROLE_ID === 1) {
              navigate(`/subscription?email=${encodeURIComponent(user?.email)}`, { state: { userType: key } });
            } else {
              console.log("res res res " ,response.data);

              const authData = {
                jwtToken: response.data.token,
                user: response.data.user,
                isOtpVerified: true,
                roleId: response.data.user.roleId,
              };
              dispatch(setAuth(authData));
              
              navigate("/guest-dashboard", { state: { userType: key } });
            }
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(
            error.response?.data?.message || "An unexpected error occurred"
          );
        }
        setOtp(["", "", "", ""]);
      }
    };

    fetchData();
  }, [otp]);

  useEffect(() => {
    if (!isActive) {
      var timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    }

    if (count === 0) setIsActive(true);

    return () => clearInterval(timer);
  }, [count]);

  const handleResendOtp = async () => {
    try {
      let response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_RESEND_OTP,
        data: {
          email: USER_EMAIL,
          roleId: ROLE_ID,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200) toast.success(response.data.message);
      else toast.error(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }

    setIsActive(false);
    setCount(59);
  };

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      if (value.length > 1) {
        value = value.slice(0, 1);
      }
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (index < refs.length - 1 && value !== "") {
        refs[index + 1].current.focus();
      }
    }
  };

  return (
    <Container fluid className="login-screen">
      <Row>
        <Col md={6}>
          <div className="Login-right verify-left">
            <Image
              className="logo"
              src={process.env.PUBLIC_URL + "/logo.png"}
              fluid={true.toString()}
            />
            <div className="sign-up-fotm otp-form">
              <h2>
                <Link to="/">←</Link>Verify Your Email
              </h2>
              <p>Input the OTP code sent to: {USER_EMAIL}</p>

              <div className="varify-otp-form-wrp">
                <Form>
                  <Form.Group className="" controlId="formGridAddress1">
                    <Form.Control
                      type="text"
                      placeholder=""
                      maxLength="1"
                      pattern="[0-9]*"
                      value={otp[0]}
                      onChange={(e) => handleChange(0, e.target.value)}
                      ref={refs[0]}
                    />
                  </Form.Group>

                  <Form.Group className="" controlId="formGridAddress2">
                    <Form.Control
                      type="text"
                      placeholder=""
                      maxLength="1"
                      pattern="[0-9]*"
                      value={otp[1]}
                      onChange={(e) => handleChange(1, e.target.value)}
                      ref={refs[1]}
                    />
                  </Form.Group>

                  <Form.Group className="" controlId="formGridAddress3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      maxLength="1"
                      pattern="[0-9]*"
                      value={otp[2]}
                      onChange={(e) => handleChange(2, e.target.value)}
                      ref={refs[2]}
                    />
                  </Form.Group>

                  <Form.Group className="" controlId="formGridAddress4">
                    <Form.Control
                      type="text"
                      placeholder=""
                      maxLength="1"
                      pattern="[0-9]*"
                      value={otp[3]}
                      onChange={(e) => handleChange(3, e.target.value)}
                      ref={refs[3]}
                    />
                  </Form.Group>
                </Form>
              </div>
              <p
                className={`have-an-account have-an-account-otp ${
                  isActive ? "active-resend-otp-btn" : ""
                }`}
                onClick={isActive ? handleResendOtp : null}
              >
                Send a new OTP code{" "}
                {!isActive && <span className="otp-time">({count})</span>}
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} className="animation-wrapper">
          <div className="login-left">
            <Image
              className="lock"
              src={process.env.PUBLIC_URL + "/lock.png"}
              fluid={true.toString()}
            />
            <Image
              className="key"
              src={process.env.PUBLIC_URL + "/key.png"}
              fluid={true.toString()}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyEmail;
