import React, { useState } from "react";
import { Container, Image, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setAuth } from "../../features/user/userAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CustomEmail = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    logo: null,
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo") {
      setFormData({ ...formData, logo: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner when form is submitted

    try {
      const data = new FormData();
      data.append("email", user?.email); // Include actual email and other necessary data
      // data.append("email", "goodgammer321@gmail.com"); // Include actual email and other necessary data
      data.append("roleId", 1); // Example roleId, update accordingly
      data.append("avatarFile", formData.logo); // Matches the multer configuration
      data.append("subject", formData.subject);
      data.append("message", formData.message);
      data.append("compaign_key", "exampleKey"); // Replace with actual campaign key

      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_CREATE_EMAIL,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response: ", response.data);
      // Handle both 200 (update) and 201 (creation) status as success
      if (response.status === 201 || response.status === 200) {
        const authData = {
          jwtToken: response.data.token,
          user: response.data.user,
          isOtpVerified: response.data.user.isOtpVerified,
          roleId: response.data.user.roleId,
          subscriptionExpired: response.data.user.sub_end_date,
          subscriptionEndDate: response.data.user.sub_end_date,
          subscription: response.data.user.subscription,
        };

        dispatch(setAuth(authData));
        navigate("/dashboard", { state: { userType: "host" } });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="mt-5">
      <div className="Login-right verify-left">
        <Image
          className="logo"
          src={process.env.PUBLIC_URL + "/logo.png"}
          fluid={true.toString()}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="card shadow-lg p-4" style={{ width: "1200px" }}>
          <h3 className="text-center mb-4 font-semibold">Email Form</h3>
          <p className="mb-4 text-center">
            Please provide the necessary details below, including the email
            subject, message content, and your chosen logo.
          </p>
          <form onSubmit={handleSubmit}>
            {/* Logo Upload */}
            <div className="form-group row mb-3">
              <label htmlFor="logo" className="col-sm-3 col-form-label">
                Upload Logo (PNG, JPEG):
              </label>
              <div className="col-sm-9">
                <input
                  type="file"
                  className="form-control"
                  id="logo"
                  name="logo"
                  accept="image/png, image/jpeg"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Subject Line */}
            <div className="form-group row mb-3">
              <label htmlFor="subject" className="col-sm-3 col-form-label">
                Email Subject:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Message Content */}
            <div className="form-group row mb-4">
              <label htmlFor="message" className="col-sm-3 col-form-label">
                Email Message:
              </label>
              <div className="col-sm-9">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Submit Info"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CustomEmail;
