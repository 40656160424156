import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import "./PaymentSuccess.css";

const PaymentSuccess = () => {
  return (
    <Wrapper>
      <div className="card-sucess green-bx-scs">
        <div class="card">
          <div
            className="tick-machline"
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
          >
            <i class="checkmark">✓</i>
          </div>
          <h1>Success</h1>
          <p>Credits have been added to your account!</p>
          <h5>Instructions</h5>
          <ul>
            <li>You can use these credits to purchase sex offender checks.</li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default PaymentSuccess;
