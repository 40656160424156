import React from 'react'
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import StaffSidebar from './StaffSidebar';
import Image from 'react-bootstrap/Image';
import GuestSidebar from './GuestSidebar';
import AdminSidebar from './AdminSidebar';
function Siderbar({isActive}) {
  const getUser = useSelector(state => state.userAuth.user);

  return (
    <>
     <Col md={2}  className={isActive ? 'p-0 toggle-transition sidebarinactive' : 'p-0 toggle-transition sidebaractive'}>
        <div className='side-bar-wrapper'>
            <Image src={ process.env.PUBLIC_URL + "/logo.svg"} fluid={true.toString()}/>
            {getUser.roleId === 1 &&<StaffSidebar/>}
            {getUser.roleId === 2 &&<GuestSidebar/>}
            {getUser.roleId === 3 &&<AdminSidebar/>}
        </div>
      </Col>
      </>
  )
}

export default Siderbar