import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LazyLoadImage from "../LazyLoad/LazyLoad";

import "./ForgotPassword.css";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [key, setKey] = useState("guest");
  const [roleId, setRoleId] = useState(2);
  const [isSpinnerActive, setisSpinnerActive] = useState(false);

  const handleForgot = async (e) => {
    e.preventDefault();
    try {
      setisSpinnerActive(true);
      let response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_FORGOT_PASSWORD,
        data: {
          email: email,
          roleId: roleId,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      setEmail("");
      if (response.status === 200) toast.success(response.data.message);
      else toast.error(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setisSpinnerActive(false);
  };
  const handleSelectTab = (k) => {
    setKey(k);
    setRoleId(k === "guest" ? 2 : 1);
  };
  return (
    <Container fluid className="register-screen">
      <LazyLoadImage
        effect="blur"
        className="logo"
        src={process.env.PUBLIC_URL + "/logo.png"}
        fluid={true.toString()}
      />

      <Tabs
        activeKey={key}
        id="uncontrolled-tab-example"
        onSelect={handleSelectTab}
        className="mb-3"
      >
        <Tab eventKey="guest" title="Guest Reset">
          <Row>
            <Col md={6} className="animation-wrapper">
              <div className="forgot-password-left">
                <Image
                  className="sun"
                  src={process.env.PUBLIC_URL + "/sun.png"}
                  fluid={true.toString()}
                />
                <Image
                  className="building"
                  src={process.env.PUBLIC_URL + "/login-home.png"}
                  fluid={true.toString()}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="forgot-password-right">
                <Image
                  className="logo"
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  fluid={true.toString()}
                />

                <div className="forgot-password-form">
                  <h2>Forgot Your Password</h2>
                  <p className="forgot-password-form__description">
                    Enter the email associated with your account and click
                    'Request Reset Link' to receive and email contaning a link
                    to reset the account password.
                  </p>

                  <div className="forgot-password-form-wrp">
                    <Form onSubmit={handleForgot}>
                      <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSpinnerActive}
                      >
                        {isSpinnerActive ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}
                        &nbsp; Request Reset Link
                      </Button>
                    </Form>
                  </div>
                </div>

                <div className="login-signup-form-btm-sec login-signup-form-btm-sec-dv">
                  <p className="have-an-account">
                    Already have an account? <Link to="/">Login</Link>
                  </p>
                  <p className="account-or-forgot">OR</p>
                  <p className="have-an-account">
                    Don’t have an account? <Link to="/register">Sign up</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="host" title="Host Reset">
          <Row>
            <Col md={6} className="animation-wrapper">
              <div className="forgot-password-left">
                <Image
                  className="sun"
                  src={process.env.PUBLIC_URL + "/sun.png"}
                  fluid={true.toString()}
                />
                <Image
                  className="building"
                  src={process.env.PUBLIC_URL + "/login-home.png"}
                  fluid={true.toString()}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="forgot-password-right">
                <Image
                  className="logo"
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  fluid={true.toString()}
                />

                <div className="forgot-password-form">
                  <h2>Forgot Your Password</h2>
                  <p className="forgot-password-form__description">
                    Enter the email associated with your account and click
                    'Request Reset Link' to receive and email contaning a link
                    to reset the account password.
                  </p>

                  <div className="forgot-password-form-wrp">
                    <Form onSubmit={handleForgot}>
                      <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSpinnerActive}
                      >
                        {isSpinnerActive ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}
                        &nbsp; Request Reset Link
                      </Button>
                    </Form>
                  </div>
                </div>

                <div className="login-signup-form-btm-sec login-signup-form-btm-sec-dv">
                  <p className="have-an-account">
                    Already have an account? <Link to="/">Login</Link>
                  </p>
                  <p className="account-or-forgot">OR</p>
                  <p className="have-an-account">
                    Don’t have an account? <Link to="/register">Sign up</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default ForgotPassword;
