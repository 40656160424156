import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Wrapper from "../Wrapper/Wrapper";
import { useSelector } from "react-redux";
import axios from "axios";
import RewardModal from "./RewardModal";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner
import "react-toastify/dist/ReactToastify.css";
import "../GuestReview/index.css";

const RewardsOverView = () => {
  const [modalShow, setModalShow] = useState(false);
  const { jwtToken } = useSelector((state) => state.userAuth);
  const user = useSelector((state) => state.userAuth.user);
  const hostId = user.id;

  const [rewards, setRewards] = useState([]);
  const [filteredRewards, setFilteredRewards] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [step, setStep] = useState(1);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.get(
          `${process.env.REACT_APP_API_GET_All_REWARDS}?hostId=${hostId}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        setRewards(response.data.rewards);
        setFilteredRewards(response.data.rewards);
      } catch (error) {
        toast.error("Failed to fetch rewards");
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };

    fetchRewards();
  }, [jwtToken, hostId]);

  const handleCloseModal = () => {
    setModalShow(false);
    setStep(1);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = rewards.filter((reward) =>
      reward.email.toLowerCase().includes(query)
    );
    setFilteredRewards(filtered);
  };

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Rewards Review Page</h2>
          <button
            className="button-primary"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            Create Reward
          </button>
          <RewardModal
            show={modalShow}
            onHide={handleCloseModal}
            step={step}
            setStep={setStep}
            setCustomersData={setRewards}
          />
        </div>
        <div className="table-wrapper">
          <InputGroup className="mb-3 serach-ontable">
            <InputGroup.Text id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by Email"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          {/* Show Spinner if loading, else show table */}
          {loading ? (
            <div className="d-flex justify-content-center">
                         <Spinner variant="primary" animation="border" />
            </div>
          ) : (
            <Table responsive="lg">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Reward</th>
                </tr>
              </thead>
              {filteredRewards.length > 0 ? (
                <tbody>
                  {filteredRewards.map((reward) => (
                    <tr key={reward.id}>
                      <td>{reward.id}</td>
                      <td>{reward.email}</td>
                      <td>{reward.firstName}</td>
                      <td>{reward.lastName}</td>
                      <td>{reward.amount}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="5">No rewards data available</td>
                  </tr>
                </tbody>
              )}
            </Table>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default RewardsOverView;
