import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Header>
              <h3 className="card-title">Terms and Conditions</h3>
            </Card.Header>
            <Card.Body>
              <h5>Introduction</h5>
              <p>
                Welcome to our website. These Terms and Conditions govern your use of our website. By accessing or using the website, you agree to be bound by these Terms and Conditions.
              </p>
              <h5>Use of the Website</h5>
              <p>
                You agree to use the website in accordance with all applicable laws and regulations. You are responsible for any content you post or share on the website.
              </p>
              <h5>Intellectual Property</h5>
              <p>
                All content on the website, including text, images, and logos, is owned by us or our licensors and is protected by intellectual property laws.
              </p>
              <h5>Limitation of Liability</h5>
              <p>
                We are not liable for any damages arising from your use of the website. We do not guarantee that the website will be available at all times or free from errors.
              </p>
              <h5>Changes to Terms</h5>
              <p>
                We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on the website.
              </p>
              <h5>Contact Us</h5>
              <p>
                If you have any questions about these Terms and Conditions, please contact us at [your email address].
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
