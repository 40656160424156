import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Wrapper from "../Wrapper/Wrapper";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Rating } from "react-simple-star-rating";
import './index.css';

function MyVerticallyCenteredModal(props) {
  const [cleanRating, setCleanlessRating] = useState(0);
  // const [ruleAdherenceRating, setRuleAdherence] = useState(0);
  // const [message, setMessage] = useState("");
  // const [isChecked, setIsChecked] = useState(false);
  // const [checkIn, setCheckIn] = useState("");
  // const [checkOut, setCheckOut] = useState("");

  const addRating = (e) => {
    e.preventDefault();
    props.setModalShow(false);
    toast.success("Rating Added Successfully");
  };

  const cleanlessRating = (rate) => {
    setCleanlessRating(rate);
  };

  // const ruleAdherence = (rate) => {
  //   setRuleAdherence(rate);
  // };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="model-custom-dsg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rate Guest Behaviour
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={addRating}>
          <div className="pop-up-step-one">
            {/* Commented out code can be enabled based on your needs */}
            {/* <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Input Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Property Name</Form.Label>
              <Form.Control type="text" placeholder="Input Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGridCheckIn">
              <Form.Label>Check-In</Form.Label>
              <Form.Control
                type="datetime-local"
                value={checkIn}
                onChange={(e) => setCheckIn(e.target.value)}
                name="checkIn"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGridCheckOut">
              <Form.Label>Check-Out</Form.Label>
              <Form.Control
                type="datetime-local"
                value={checkOut}
                onChange={(e) => setCheckOut(e.target.value)}
                name="checkOut"
              />
            </Form.Group>
            <div className="cleanless-star-rating-sec">
              Cleanless <Rating onClick={cleanlessRating} />
            </div>
            <div className="adherence-star-rating-sec">
              Rule Adherence <Rating onClick={ruleAdherence} />
            </div>
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Message</Form.Label>
              <Form.Control
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Input Address"
                name="message"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCheckbox">
              <Form.Check
                type="checkbox"
                label="Nominate for reward"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            </Form.Group> */}
            <div style={{ marginBottom: "20px" }}>
              <Rating onClick={cleanlessRating} />
            </div>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const GuestReview = () => {
  const [modalShow, setModalShow] = useState(false);
  const { jwtToken } = useSelector((state) => state.userAuth);
  const [showModal, setShowModal] = useState(false);
  // const [responseData, setResponseData] = useState(null);
  // const [guest, setGuest] = useState(null);
  const [guestList, setGuestList] = useState([]);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleShowModal = (response) => {
    // setShowModal(true);
    // setResponseData(response);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getGuestList = async () => {
    try {
      let response = await axios({
        method: "get",
        url: process.env.REACT_APP_API_GET_All_GUEST,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        setGuestList(response.data.users);
        setFilteredGuests(response.data.users);
      }
    } catch (error) {
      handleShowModal(error.response?.data?.message || "An error occurred");
      toast.error("Error fetching guest list");

    }
  };

  useEffect(() => {
    getGuestList();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = guestList.filter(
      (guest) =>
        guest.email.toLowerCase().includes(query) ||
        guest.id.toString().includes(query)
    );
    setFilteredGuests(filtered);
  };

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="table-page-top">
          <h2>Guest Review Page</h2>

          <MyVerticallyCenteredModal
            show={modalShow}
            setModalShow={setModalShow}
            onHide={() => setModalShow(false)}
          />
        </div>

        <div className="table-wrapper">
        <InputGroup className="mb-3 serach-ontable">
            <InputGroup.Text id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by Guest Unique Id or Email"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          <Table responsive="lg">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            {filteredGuests.length > 0 ? (
              <tbody>
                {filteredGuests.map((guest) => (
                  <tr key={guest.id}>
                    <td>{guest.id}</td>
                    <td>
                      {guest.firstName} {guest.lastName}
                    </td>
                    <td>{guest.email}</td>
                    <td>
                      <Button
                        style={{ color: "rgb(255, 255, 255)" }}
                        className="button-primary"
                        variant="primary"
                        onClick={() => setModalShow(true)}
                      >
                        Rate
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="4">No guest data available</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>
    </Wrapper>
  );
};

export default GuestReview;
