import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
const GuestSidebar = () => {
  return (
    <Navbar expand="lg" className="flex-column">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column">
          {/* <Nav.Item>
            <NavLink end to="/guest-dashboard">
              <Image src={process.env.PUBLIC_URL + "/nav-ico1.svg"} fluid={true.toString()} />{" "}
              Dashboard
            </NavLink>
          </Nav.Item> */}
          <Nav.Item>
            <NavLink end to="/guest-dashboard">
              <Image
                src={process.env.PUBLIC_URL + "/nav-ico6.svg"}
                fluid={true.toString()}
              />{" "}
              My Rewards
            </NavLink>
          </Nav.Item>
          {/* <Nav.Item>
            <NavLink end to="/guest-dashboard/my-point">
              <Image
                src={process.env.PUBLIC_URL + "/nav-ico6.svg"}
                fluid={true.toString()}
              />{" "}
              My Points
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink end to="/guest-dashboard/my-reward">
              <Image
                src={process.env.PUBLIC_URL + "/nav-ico6.svg"}
                fluid={true.toString()}
              />{" "}
              My Rewards
            </NavLink>
          </Nav.Item> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default GuestSidebar;
