import React,{ useState } from "react";
import DashboardContext from "./DashboardContext";

const DashboardContextProvider= (props)=>{
    const [isActive, setIsActive] = useState(false);
    const [user, setUser] = useState(false);
    const handleClick = () => {
        setIsActive(!isActive);
    };
    const handleSetUser = (userData) => {
        setUser(userData);
      };
    return (
        <>
            <DashboardContext.Provider value={ { isActive,setIsActive,handleClick , handleSetUser , user} }>
                {props.children}
            </DashboardContext.Provider>
        </>
    );
}

export default DashboardContextProvider;