import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { setAuth } from "../../features/user/userAuth";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
  Image,
} from "react-bootstrap";

function Subscription() {
  const dispatch = useDispatch();
  // const User = useSelector((state) => state.userAuth.user);
  const { user } = useContext(DashboardContext);
  const location = useLocation();
  const [key, setKey] = useState("guest");
  localStorage.setItem("user", JSON.stringify(user));
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const navigate = useNavigate();
  const [isSpinnerActive, setisSpinnerActive] = useState(false);

  useEffect(() => {
    if (location.state?.userType) {
      const userType = location.state.userType;
      setKey(userType);
    }
  }, [location.state?.userType]);
  
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      console.log("user", user?.email);
    } else {
      let testUser = JSON.parse(localStorage.getItem('user'));
      console.log("testUser from local storage", testUser?.email);  
    }
  }, [])
  
  
  const handleSubscription = async (type) => {
    try {
      setisSpinnerActive(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SUBSCRIPTION}`,
        {
          email: user?.email ?? email,
          subscriptionType: type,
          success_url: process.env.REACT_APP_API_SUB_PAYMENT_SUCCESSFUL,
          cancel_url: `${process.env.REACT_APP_API_SUB_PAYMENT_FAILED}?email=${encodeURIComponent(user?.email ?? email)}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {

        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          const authData = {
            jwtToken: response.data.token,
            user: response.data.user,
            isOtpVerified: response.data.user.isOtpVerified,
            roleId: response.data.user.roleId,
            subscriptionExpired: response.data.user.sub_end_date,
            subscriptionEndDate: response.data.user.sub_end_date,
            subscription: response.data.user.subscription,
          };

          dispatch(setAuth(authData));
          navigate("/dashboard", { state: { userType: key } });
          toast.success(
            response.data.message || "Subscription updated successfully!"
          );
        }
      } else {
        toast.error(response.data.message || "Subscription failed!");
      }
    } catch (error) {
      toast.error("An error occurred during subscription!");
    } finally {
      setisSpinnerActive(false);
    }
  };

  return (
    <Container fluid className="mt-5">
      <div className="Login-right verify-left">
        <Image
          className="logo"
          src={process.env.PUBLIC_URL + "/logo.png"}
          fluid={true.toString()}
        />
      </div>
      <main>
        <Row className="row-cols-1 row-cols-md-3 mb-3 text-center align-items-stretch">
          <Col>
            <Card className="mb-4 rounded-3 shadow-sm h-100 subscription-card">
              <Card.Header>
                <h4 className="my-0 fw-normal">Free</h4>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <h1 className="card-title pricing-card-title">
                  $0<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="mt-3 mb-4">
                  <li>Pay-as-you-go model for rewards: $2 per reward sent.</li>
                  <li>No automation or scheduled reward features.</li>
                  <li>No custom branded email capabilities.</li>
                  <li>No sex offender search credits.</li>
                </ul>
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="w-100 subscription-btn mt-auto"
                  onClick={() => handleSubscription("Basic")}
                >
                  Basic
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-4 rounded-3 shadow-sm h-100 subscription-card">
              <Card.Header>
                <h4 className="my-0 fw-normal">Premium</h4>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <h1 className="card-title pricing-card-title">
                  $50<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="mt-3 mb-4">
                  <li>All features included in the Basic Plan.</li>
                  <li>
                    Custom Branded Emails: Capability to send custom-branded
                    emails created by a team member.
                  </li>
                  <li>Sex Offender Searches: 20 search credits per month.</li>
                  <li>
                    No monthly reward fee; pay-per-reward model at $2 per reward
                    sent.
                  </li>
                </ul>
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="w-100 subscription-btn mt-auto"
                  onClick={() => handleSubscription("Premium")}
                >
                  Premium
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-4 rounded-3 shadow-sm h-100 subscription-card">
              <Card.Header>
                <h4 className="my-0 fw-normal">Platinum</h4>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                {/* <h1 className="card-title pricing-card-title">
                  Comming<small className="text-muted fw-light">Soon</small>
                </h1> */}
                {/* <ul className="mt-3 mb-4">
                  <li>30 users included</li>
                  <li>15 GB of storage</li>
                  <li>Phone and email support</li>
                  <li>Help center access</li>
                </ul> */}
                <Button
                  variant="outline-secondary"
                  size="lg"
                  className="w-100 subscription-btn mt-auto"
                  disabled
                >
                  Platinum (Coming Soon)
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </main>
      {isSpinnerActive && (
        <Row className="justify-content-center">
          <Col className="text-center">
            <Spinner variant="primary" animation="border" />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Subscription;
