import React from 'react'
import './NotFound404.css'
import { Link } from 'react-router-dom'

function NotFound404() {
    return (
        <div className='page-not-fnd'>
            <h1>404</h1>
            <p>Oops! Something is wrong.</p>
            <Link to="/dashboard" class="button" href="#"> Go back in initial page, is better.</Link>
        </div>    
    )
}

export default NotFound404