import React from 'react';
import Modal from 'react-bootstrap/Modal';

const CustomModal = ({ children,heading, show, onHide}) => {
    return (

        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='model-custom-dsg'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
}

export default CustomModal;
