import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import "./ResetPassword.css";
import LazyLoadImage from "../LazyLoad/LazyLoad";

function ResetPassword() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });

  const { token } = useParams();
  const [isSpinnerActive, setisSpinnerActive] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name.trim()]: value.trim() });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setisSpinnerActive(true);
      const { confirm_password, password } = formData;

      if (confirm_password !== password) {
        toast.error("Passwords does not match");
        setisSpinnerActive(false);
        return;
      }

      const response = await axios.post(
        process.env.REACT_APP_API_RESET_PASSWORD + "/" + token,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      setFormData({
        password: "",
        confirm_password: "",
      });
      if (response.status === 200) toast.success(response.data.message);
      else toast.error(response.data.message);
      navigate("/");
    } catch (error) {
      setFormData({
        password: "",
        confirm_password: "",
      });
      toast.error(error.response.data.message);
    }
    setisSpinnerActive(false);
  };

  return (
    <Container fluid className="register-screen">
      <Row>
        <Col md={6} className="animation-wrapper">
          <div className="register-left">
            <div className="sun">
              <LazyLoadImage
                effect="blur"
                src={process.env.PUBLIC_URL + "/sun.png"}
                fluid={true.toString()}
              />
            </div>
            <div className="building">
              <LazyLoadImage
                effect="blur"
                src={process.env.PUBLIC_URL + "/login-home.png"}
                fluid={true.toString()}
              />
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div className="register-right">
            <LazyLoadImage
              effect="blur"
              className="logo"
              src={process.env.PUBLIC_URL + "/logo.png"}
              fluid={true.toString()}
            />

            <div className="sign-up-form">
              <h2>Reset Your Password</h2>

              <div className="reset-password-form-wrp">
                <Form onSubmit={handleRegister}>
                  <Form.Group className="mb-3" controlId="formGridPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      autoComplete="new-password"
                      type="password"
                      placeholder="Enter Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />

                    <Form.Text className="form-text text-muted from-input-description">
                      Password must include at least one uppercase letter, one
                      lowercase letter, one number, and one special character.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="formGridConfirmPassword"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      autoComplete="new-password"
                      type="password"
                      placeholder="Enter Password Again"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSpinnerActive}
                  >
                    {isSpinnerActive ? (
                      <Spinner animation="border" size="sm" />
                    ) : null}
                    &nbsp; Reset Password
                  </Button>
                </Form>
              </div>
              <p className="have-an-account">
                Already have an account? <Link to="/">Login</Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
