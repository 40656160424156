import React from "react";
import { useSelector } from "react-redux";
import "../Dashboard.css";
import "./DashboardMain.css";
import Wrapper from "../Wrapper/Wrapper";
import GuestDashboard from "./GuestDashboard";
import StaffDashboard from "./StaffDashboard";
import AdminDashboard from "./AdminDashboard";

const Dashboard = () => {
  const { user } = useSelector((state) => state.userAuth);
  

  return (
    <Wrapper>
      {user.roleId == 1 && <StaffDashboard credit={user.userCredits} subscription={user.subscription} subscriptionEndDate={user.sub_end_date }  />}
      {user.roleId == 2 && <GuestDashboard />}
      {user.roleId == 3 && <AdminDashboard />}
    </Wrapper>
  );
};

export default Dashboard;
