import React from "react";
import { Link } from "react-router-dom";
import LazyLoadImage from "../../LazyLoad/LazyLoad";
import Wrapper from '../Wrapper/Wrapper';

const AdminDashboard = () => {
  return (
    <Wrapper>
    <div className="dashboard-choice-wrapper">
      <div className="credit-balance">
        <p>
         Credit Balance
        </p>
      </div>

      <div className="dash-choose-op-wrp">
        <h2>What would you like to do today?</h2>
        <p>Choose menu you want to do today</p>

        <div className="dash-option-wrp">
          <Link to="/admin-dashboard/host-review">
            <LazyLoadImage
              effect="blur"
              src={process.env.PUBLIC_URL + "/op-1.svg"}
              fluid={true.toString()}
            />
            Host Review
          </Link>
        </div>
      </div>
    </div>
    </Wrapper>
  );
};

export default AdminDashboard;
