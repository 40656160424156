import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import "./PaymentSuccess.css";

const RewardPaymentSuccess = () => {
    return (
        <Wrapper>
            <div className="card-sucess green-bx-scs">
                <div class="card">
                    <div
                        className="tick-machline"
                        style={{
                            borderRadius: "200px",
                            height: "200px",
                            width: "200px",
                            background: "#F8FAF5",
                            margin: "0 auto",
                        }}
                    >
                        <i class="checkmark">✓</i>
                    </div>
                    <h1>Success</h1>
                    <p>Great! Your guests reward is on its way! 🎉</p>
                </div>
            </div>
        </Wrapper>
    );
};

export default RewardPaymentSuccess;
