import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
const AdminSidebar = () => {
  return (
    <Navbar expand="lg" className="flex-column">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column">
          <Nav.Item>
            <NavLink end to="/admin-dashboard">
              <Image src={process.env.PUBLIC_URL + "/nav-ico1.svg"} fluid={true.toString()} />{" "}
              Dashboard
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink end to="/admin-dashboard/host-review">
              <Image src={process.env.PUBLIC_URL + "/nav-ico3.svg"} fluid={true.toString()} />{" "}
              Host Review
            </NavLink>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AdminSidebar;
