import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { persistor, store } from './app/store'
import DashboardContextProvider from './context/Dashboard/DashboardContextProvider';
import { ToastContainer } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DashboardContextProvider>
        <App />
        <ToastContainer />
      </DashboardContextProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
