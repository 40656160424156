import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Button, Card, Spinner } from "react-bootstrap";
import Wrapper from "../Wrapper/Wrapper";

function UpdateSub() {
  const subscriptionExpired = useSelector(
    (state) => state.userAuth.subscriptionExpired
  );
  const user = useSelector((state) => state.userAuth.user);
  const navigate = useNavigate();
  const [isSpinnerActive, setisSpinnerActive] = useState(false);

  const handleSubscription = async (type) => {
    try {
      setisSpinnerActive(true); // Show spinner

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SUBSCRIPTION}`,
        {
          email: user?.email,
          subscriptionType: type,
          success_url: process.env.REACT_APP_API_UP_SUB_PAYMENT_SUCCESSFUL,
          cancel_url: process.env.REACT_APP_API_UP_SUB_PAYMENT_FAILED,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          navigate("/dashboard");
          toast.success(
            response.data.message || "Subscription updated successfully!"
          );
        }
      } else {
        toast.error(response.data.message || "Subscription failed!");
      }
    } catch (error) {
      toast.error("An error occurred during subscription!");
    } finally {
      setisSpinnerActive(false);
    }
  };

  return (
    <Wrapper>
      <Container fluid className="mt-5">
        {subscriptionExpired && (
          <div className="alert alert-warning text-center" role="alert">
            Your subscription has expired. Please subscribe again to continue
            using our services.
          </div>
        )}
        <main>
          <Row className="row-cols-1 row-cols-md-3 mb-3 text-center align-items-stretch">
            <Col>
              <Card className="mb-4 rounded-3 shadow-sm h-100 subscription-card">
                <Card.Header>
                  <h4 className="my-0 fw-normal">Free</h4>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <h1 className="card-title pricing-card-title">
                    $0<small className="text-muted fw-light">/mo</small>
                  </h1>
                  <ul className="mt-3 mb-4">
                    <li>
                      Pay-as-you-go model for rewards: $2 per reward sent.
                    </li>
                    <li>No automation or scheduled reward features.</li>
                    <li>No custom branded email capabilities.</li>
                    <li>No sex offender search credits.</li>
                  </ul>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="w-100 subscription-btn mt-auto"
                    onClick={() => handleSubscription("Basic")}
                  >
                    Basic
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-4 rounded-3 shadow-sm h-100 subscription-card">
                <Card.Header>
                  <h4 className="my-0 fw-normal">Premium</h4>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <h1 className="card-title pricing-card-title">
                    $50<small className="text-muted fw-light">/mo</small>
                  </h1>
                  <ul className="mt-3 mb-4">
                    <li>All features included in the Basic Plan.</li>
                    <li>
                      Custom Branded Emails: Capability to send custom-branded
                      emails created by a team member.
                    </li>
                    <li>Sex Offender Searches: 20 search credits per month.</li>
                    <li>
                      No monthly reward fee; pay-per-reward model at $2 per
                      reward sent.
                    </li>
                  </ul>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="w-100 subscription-btn mt-auto"
                    onClick={() => handleSubscription("Premium")}
                  >
                    Premium
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-4 rounded-3 shadow-sm h-100 subscription-card">
                <Card.Header>
                  <h4 className="my-0 fw-normal">Platinum</h4>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  {/* <h1 className="card-title pricing-card-title">
                  Comming<small className="text-muted fw-light">Soon</small>
                </h1> */}
                  {/* <ul className="mt-3 mb-4">
                  <li>30 users included</li>
                  <li>15 GB of storage</li>
                  <li>Phone and email support</li>
                  <li>Help center access</li>
                </ul> */}
                  <Button
                    variant="outline-secondary"
                    size="lg"
                    className="w-100 subscription-btn mt-auto"
                    disabled
                  >
                    Platinum (Coming Soon)
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </main>
        {isSpinnerActive && (
          <Row className="justify-content-center">
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  );
}

export default UpdateSub;
