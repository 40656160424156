import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LazyLoadImage from "../../LazyLoad/LazyLoad";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosWithAuthErrorHandling from "../../AxiosErrorHandling/AxiosErrorHandling";

export default function MyVerticallyCenteredModal({ show, onHide }) {
  const navigate = useNavigate();
  const axios = useAxiosWithAuthErrorHandling();
  const { jwtToken, user } = useSelector((state) => state.userAuth);
  const [key, setKey] = useState("general");
  const [avatar, setAvatar] = useState(user.avatar);
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });

  const subscription = user.subscription;

  const [updateformData, setupdateFormData] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    gender: "male",
    avatarFile: null,
  });

  const roleId = user?.roleId;
  const userEmail = user?.email;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name.trim()]: value.trim() });
  };

  const handleDashpasswordChange = async (e) => {
    e.preventDefault();
    try {
      const { confirm_password, password } = formData;

      if (confirm_password !== password || !confirm_password || !password) {
        toast.error("Passwords do not match");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_RESET_PASSWORD}/${jwtToken}`,
        {
          password,
          confirm_password,
          roleId,
          email: userEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setFormData({
        password: "",
        confirm_password: "",
      });

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setFormData({
        password: "",
        confirm_password: "",
      });
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleupdateFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();
      data.append("userName", updateformData.userName);
      data.append("firstName", updateformData.firstName);
      data.append("lastName", updateformData.lastName);
      data.append("gender", updateformData.gender);
      data.append("roleId", roleId);
      data.append("userEmail", userEmail);
      if (updateformData.avatarFile) {
        data.append("avatarFile", updateformData.avatarFile);
      }

      let response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_UPDATE_PROFILE,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value, files } = e.target;
    setupdateFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));

    if (name === "avatarFile" && files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="model-custom-dsg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="general" title="General Info">
            <Form onSubmit={handleupdateFormSubmit}>
              <div className="pop-tabs-form">
                <div className="change-avatar-sec">
                  <LazyLoadImage
                    effect="blur"
                    src={
                      avatar ? avatar : process.env.PUBLIC_URL + "/avatar.png"
                    }
                    fluid={true.toString()}
                  />
                  <input
                    type="file"
                    accept="LazyLoadImage/*"
                    name="avatarFile"
                    onChange={handleUpdateInputChange}
                    style={{ display: "none" }}
                  />
                  <span
                    onClick={() =>
                      document.querySelector('input[type="file"]').click()
                    }
                  >
                    Change Avatar
                  </span>
                </div>
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Input Name"
                    name="userName"
                    value={updateformData.userName}
                    onChange={handleUpdateInputChange}
                  />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter here"
                      name="firstName"
                      value={updateformData.firstname}
                      onChange={handleUpdateInputChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter here"
                      name="lastName"
                      value={updateformData.lastname}
                      onChange={handleUpdateInputChange}
                    />
                  </Form.Group>
                </Row>

                <Form.Group controlId="formGridAddress1">
                  <Form.Label>Gender</Form.Label>
                </Form.Group>

                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  className="mb-3"
                  value={updateformData.gender}
                  onChange={handleUpdateInputChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>

                <Button variant="primary" type="submit">
                  {" "}
                  Save{" "}
                </Button>
              </div>
            </Form>
          </Tab>
          {roleId === 1 && (
            <Tab eventKey="subscription" title="Subscription">
              <Form>
                <div className="pop-tabs-form">
                  <p className="tabs-form-top-heading-line">Subscription</p>
                  {subscription?.toLowerCase() === "premium" && (
                    <Button
                      variant="primary"
                      onClick={() => navigate("/dashboard/update-email")}
                    >
                      Update Custom Email
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    onClick={() => navigate("/dashboard/update-subscription")}
                  >
                    Update Subscription Plan
                  </Button>
                </div>
              </Form>
            </Tab>
          )}

          <Tab eventKey="security" title="Security">
            <Form onSubmit={handleDashpasswordChange}>
              <div className="pop-tabs-form">
                <p className="tabs-form-top-heading-line">Change Password</p>
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="Password"
                    placeholder="type your new password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>Re-type your new password</Form.Label>
                  <Form.Control
                    name="confirm_password"
                    type="Password"
                    placeholder="Re-type your new password"
                    value={formData.confirm_password}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  {" "}
                  Save{" "}
                </Button>
              </div>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}
