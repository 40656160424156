import React, { useEffect, useState } from "react";
import { Table, Form, Image, Spinner, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
// import { loadStripe } from '@stripe/stripe-js';
import Wrapper from "../Wrapper/Wrapper";
import { useSelector } from "react-redux";

import useAxiosWithAuthErrorHandling from "../../AxiosErrorHandling/AxiosErrorHandling";
import customMethods from "../../customMethods";

function MyVerticallyCenteredModal({
  show,
  onHide,
  amount,
  setAmount,
  buyCredits,
}) {
  const [customPrice, setCustomPrice] = useState(false);
  const handlePurchase = (e) => {
    e.preventDefault();
    const usersAmount = Math.floor(amount);
    if (!usersAmount) {
      toast.error("Please enter a valid amount!");
      return;
    } else {
      buyCredits("pop-up");
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="model-custom-dsg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Sex Offender Check
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="pop-up-step-one">
            <Form.Group controlId="formGridAddress1">
              <Form.Label>Credits</Form.Label>
            </Form.Group>
            {!customPrice && (
              <Form.Select
                name="credits-plan"
                onChange={(e) => setAmount(Number(e.target.value))}
                aria-label="Default select example"
                className="mb-3"
              >
                <option>Select Amount</option>
                <option value="5">$5</option>
                <option value="10">$10</option>
                <option value="15">$15</option>
                <option value="20">$20</option>
                <option value="25">$25</option>
                <option value="30">$30</option>
              </Form.Select>
            )}
            {customPrice && (
              <Form.Group className="mb-3" controlId="formGrideCustomPrice">
                <Form.Control
                  type="number"
                  placeholder="Enter Custom Price"
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-0" id="formGridCheckbox">
              <Form.Check
                type="checkbox"
                label="Add Custom Amount"
                value={customPrice}
                onChange={() => {
                  setAmount(0);
                  setCustomPrice((prev) => !prev);
                }}
              />
            </Form.Group>

            <Button onClick={(e) => handlePurchase(e)} variant="primary">
              Buy
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const PurchaseCredits = () => {
  const axios = useAxiosWithAuthErrorHandling();
  const { jwtToken } = useSelector((state) => state.userAuth);
  const [modalShow, setModalShow] = React.useState(false);
  const user = useSelector((state) => state.userAuth.user);

  const [amount, setAmount] = useState(0);
  //const [packAmount, setpackAmount] = useState(0);

  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevDisable, setPrevDisable] = useState("");
  const [nextDisable, setNextDisable] = useState("");
  const [tableRows, setTableRows] = useState(10);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);

  // const handlePackageBuy = (amountPack) => {
  //     setpackAmount(amountPack);
  // }

  // useEffect(() => {
  //     if (packAmount === 0) return;
  //     buyCredits('packAmount');
  // }, [packAmount]);

  const buyCredits = async (type) => {
    try {
      const data = {
        amount: Math.floor(amount),
        success_url: process.env.REACT_APP_API_PAYMENT_SUCCESSFUL,
        cancel_url: process.env.REACT_APP_API_PAYMENT_FAILED,
      };
  
      const response = await axios.post(
        process.env.REACT_APP_API_PURCHASE_CREDITS,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
  
      // Check if the payment time expired
      if (response.data.paymentExpired) {
        // Redirect to failure page
        window.location = process.env.REACT_APP_API_PAYMENT_FAILED;
      } else {
        // Redirect to the Stripe checkout URL
        window.location = response.data.url;
      }
    } catch (error) {
      console.log("Error during payment process:", error);
    }
  };
  

  const fetchPurchaseHistory = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API_GET_PURCHASE_HISTORY,
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.status === 200) {
        setPurchaseHistory(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching History:", error);
    }
    setIsLoadingHistory(false);
  };

  useEffect(() => {
    fetchPurchaseHistory();
  }, []);

  function handleTableRowChange(e) {
    e.preventDefault();
    const input = parseInt(e.target.value);
    setCurrentPage(1);
    setTableRows(input);
  }

  const indexOfLastItem = currentPage * tableRows;
  const indexOfFirstItem = indexOfLastItem - tableRows;

  const totalRecords = purchaseHistory.length;
  const currentItems = purchaseHistory.slice(indexOfFirstItem, indexOfLastItem);
  const paginationLastIndex = currentItems.length;

  const paginate = (type) => {
    const totalPages = Math.ceil(purchaseHistory.length / tableRows);

    let pageNumber;
    if (type === "next") {
      if (currentPage + 1 > totalPages) {
        setNextDisable(true);
        return;
      }
      pageNumber = currentPage + 1;
    } else {
      if (currentPage - 1 <= 0) {
        setPrevDisable(true);
        return;
      }
      pageNumber = currentPage - 1;
    }
    setCurrentPage(pageNumber);
  };
  
  useEffect(() => {
    // Check if the product tour has already been shown
    const isTourShown = localStorage.getItem('isTourShown');
    
    // If the tour has not been shown, start the tour
    if (!isTourShown) {
      console.log("🚀 Starting the tour for the first time after registration");
      try {
        window.Intercom('startTour', 567643);
        
        // Set the flag in localStorage so that the tour doesn't show again
        localStorage.setItem('isTourShown', 'true');
      } catch (error) {
        console.log("Error starting the tour:", error);
      }
    } else {
      console.log("Tour has already been shown to this user.");
    }
  }, []);
  

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="table-page-top" style={{ position: "relative" }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-3">Purchase Credits</h2>
                <h4 className="content-top-heading mb-3">
                  Current Credit:{" "}
                  {user?.userCredits != null ? user.userCredits : 0}$
                </h4>
                {/* More headings */}
              </div>
            </div>

            {/* Button with absolute positioning */}
            <div style={{ position: "absolute", right: 0, bottom: 22 }}>
              <button
                className="button-primary"
                variant="primary"
                onClick={() => setModalShow(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                </svg>
                Buy Prepaid Credits
              </button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            setAmount={setAmount}
            buyCredits={buyCredits}
            amount={amount}
          />
        </div>

        <div className="table-wrapper">
          {/* TODO: */}
          {/* <h4 className='content-top-heading'>List of Packages</h4> */}
          {/* <div className='packg-list-wrapper'>

                        <div className='package-cr'>
                            <div className='package-left-ico'>
                                <Image src={process.env.PUBLIC_URL + "/package-dollar.svg"} fluid={true.toString()}/>
                            </div>

                            <div className='package-right-cnt'>
                                <span>
                                    <p>Package 1</p>
                                    <h5>100 Credit - 10$</h5>
                                </span>

                                <button onClick={(e) => { handlePackageBuy(100) }}>Buy</button>
                            </div>
                        </div>

                    </div> */}

          <h4 className="content-top-heading">Credit Purchase History</h4>
          {!isLoadingHistory ? (
            purchaseHistory.length ? (
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Credit</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((customer, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>+{customer.creditsPurchased}</td>
                      <td>{customMethods.formatDate(customer.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="data-not-found">
                No transactions have been made!
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner variant="primary" animation="border" />
            </div>
          )}
          {purchaseHistory.length > 5 && (
            <div className="pagination-btm-tablecustom">
              <div className="select-withbx">
                <p>Rows per page:</p>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => handleTableRowChange(e)}
                >
                  <option value="10">10</option>
                  <option value="5">05</option>
                </Form.Select>
              </div>

              <div className="select-withvalue">
                <p>
                  <span>{indexOfFirstItem + 1}</span>-
                  <span>{indexOfFirstItem + paginationLastIndex}</span>
                </p>
                <p>of</p>
                <p>{totalRecords}</p>
              </div>
              <div className="select-witharrow">
                <button>
                  <Image
                    disable={prevDisable}
                    onClick={() => paginate("prev")}
                    src={process.env.PUBLIC_URL + "/arrow-left-sm.svg"}
                  />
                </button>

                <button>
                  <Image
                    disable={nextDisable}
                    onClick={() => paginate("next")}
                    src={process.env.PUBLIC_URL + "/arrow-right-sm.svg"}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default PurchaseCredits;
