import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LazyLoadImage from "../../LazyLoad/LazyLoad";
import axios from "axios";

function MyVerticallyCenteredModal({
  show,
  onHide,
  amount,
  setAmount,
  buyCredits,
}) {
  const handlePurchase = (e) => {
    e.preventDefault();
    const usersAmount = Math.floor(amount);
    if (!usersAmount) {
      toast.error("Please enter a valid amount!");
      return;
    } else {
      buyCredits("pop-up");
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="model-custom-dsg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Custom Reward
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="pop-up-step-one">
            <Form.Group controlId="formGridAddress1">
              <Form.Label>Credits</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGrideCustomPrice">
              <Form.Control
                type="number"
                placeholder="Enter Custom Price"
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </Form.Group>
            <button
              className="button-primary"
              onClick={(e) => handlePurchase(e)}
              variant="primary"
            >
              Buy
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const RewardAmount = () => {
  const { rewardId } = useParams();
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const user = useSelector((state) => state.userAuth.user);
  const subscription = user.subscription;

  const handleRewardSelection = (amount) => {
    if (amount === "custom") {
      setModalShow(true);
    } else {
      setSelectedAmount(amount);
    }
  };

  const handleConfirmClick = async () => {
    if (selectedAmount === null) {
      toast.error("Please select a reward amount before confirming.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REWARD_PAYMENT}`,
        {
          amount: selectedAmount,
          rewardId,
          subscription,
          success_url: process.env.REACT_APP_CREATE_REWARD_PAYMENT_SUCCESSFUL,
          cancel_url: process.env.REACT_APP_API_PAYMENT_FAILED,
        }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating reward session:", error);
    }
  };

  const subscriptionDetails = {
    Basic: {
      charge: 2,
      message: "An additional charge of $2 will be applied to your account due to your current Basic Subscription plan.",
    },
    Premium: {
      charge: 1,
      message: "An additional charge of $1 will be applied to your account due to your current Premium Subscription plan.",
    },
  };

  return (
    <Container fluid>
      <LazyLoadImage
        effect="blur"
        src={process.env.PUBLIC_URL + "/logo.png"}
        fluid={true.toString()}
      />
      <Card
        className="text-center p-4 mt-5"
        style={{ width: "100%", maxWidth: "800px", backgroundColor: "#F0F8FF" }}
      >
        <h4>Would you like to reward your guest?</h4>
        {subscriptionDetails[subscription] && (
          <div className="alert alert-warning text-center" role="alert">
            {subscriptionDetails[subscription].message}
          </div>
        )}
        <h2 className="my-4">${selectedAmount ? selectedAmount : 0}</h2>

        <Row className="my-4">
          <Col>
            <Button
              variant={selectedAmount === 10 ? "primary" : "light"}
              className="btn w-100 py-5"
              onClick={() => handleRewardSelection(10)}
            >
              $10
            </Button>
          </Col>
          <Col>
            <Button
              variant={selectedAmount === 50 ? "primary" : "light"}
              className="btn w-100 py-5"
              onClick={() => handleRewardSelection(50)}
            >
              $50
            </Button>
          </Col>
          <Col>
            <Button
              variant={selectedAmount === 70 ? "primary" : "light"}
              className="btn w-100 py-5"
              onClick={() => handleRewardSelection(70)}
            >
              $70
            </Button>
          </Col>
          <Col>
            <Button
              variant={selectedAmount === 100 ? "primary" : "light"}
              className="btn w-100 py-5"
              onClick={() => handleRewardSelection(100)}
            >
              $100
            </Button>
          </Col>
          <Col>
            <Button
              variant={selectedAmount === "custom" ? "primary" : "light"}
              className="btn w-100 py-5"
              onClick={() => handleRewardSelection("custom")}
            >
              Custom
            </Button>
          </Col>
        </Row>
        <hr />
        <button
          variant="primary"
          // className="w-100 py-3"
          className="button-primary"
          onClick={handleConfirmClick} // Trigger payment on confirm click
        >
          Confirm
        </button>
      </Card>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        amount={selectedAmount}
        setAmount={setSelectedAmount}
        buyCredits={() => {
          setModalShow(false);
        }}
      />
    </Container>
  );
};

export default RewardAmount;
