import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Siderbar from "../Sidebar/Siderbar";
import DashboardContext from "../../../context/Dashboard/DashboardContext";
import MyVerticallyCenteredModal from "./WrapperModal";
import { clearAuth } from "../../../features/user/userAuth";
import axios from "axios";
import { setAuth } from "../../../features/user/userAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Wrapper({ children }) {
  const [modalShow, setModalShow] = useState(false);
  const { handleSetUser } = useContext(DashboardContext);

  const { isActive, handleClick } = useContext(DashboardContext);
  const adminId = useSelector((state) => state.userAuth.adminId);
  const { user } = useSelector((state) => state.userAuth);
  const roleId = user.roleId;
  const userType = roleId === 1 ? "host" : roleId === 2 ? "guest" : "guest"; 

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleStopImpersonation = async () => {
    try {
      let response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_STOP_IMPERSONATE,
        data: {
          adminId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const authData = {
          jwtToken: response.data.token,
          user: response.data.user,
          rememberMe: false,
          isOtpVerified: response.data.isOtpVerified,
          roleId: response.data.user.roleId,
        };

        dispatch(
          setAuth({
            ...authData,
          })
        );

        if (response.data.isOtpVerified === false) {
          navigate("/verify-otp");
        } else {
          handleSetUser(response.data.user);
          navigate("/admin-dashboard");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Container className="p-0" style={{ maxWidth: "100%" }}>
        <Row className="m-0">
          {<Siderbar isActive={isActive} />}
          <Col
            md={10}
            className={
              isActive
                ? "p-0 toggle-transition dashboardwidthinactive"
                : "p-0 toggle-transition dashboardwidthactive"
            }
          >
            <div className="dashboard-content-wrapper">
              <div className="top-bar-fix">
                <div className="burgur-menu-wrap" onClick={handleClick}>
                  <Image
                    src={process.env.PUBLIC_URL + "/menu-hamburger.svg"}
                    fluid={true.toString()}
                  />
                </div>

                <div className="top-bar-content-wrp">
                  <button variant="primary" onClick={() => setModalShow(true)}>
                    <Image
                      src={process.env.PUBLIC_URL + "/setting.svg"}
                      fluid={true.toString()}
                    />
                  </button>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <span>
                    {" "}
                    <Image
                      src={
                        user.avatar || process.env.PUBLIC_URL + "/avatar.png"
                      }
                      fluid={true.toString()}
                      alt="User avatar"
                    />{" "}
                  </span>
                  {adminId ? (
                    <>
                      <div>{user.firstName}</div>
                      <button
                        onClick={handleStopImpersonation}
                        className="button-primary"
                        variant="primary"
                      >
                        Stop Impersonation
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        dispatch(clearAuth());
                        navigate("/", { state: { userType } });
                      }}
                      className="button-primary"
                      variant="primary"
                    >
                      Logout
                    </button>
                  )}
                </div>
              </div>
              <div className="dash-content-wrapper">{children}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Wrapper;
