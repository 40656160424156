import React, { useState, useEffect } from "react";
import { Table, Form, InputGroup, Image, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Wrapper from "../Wrapper/Wrapper";
import useAxiosWithAuthErrorHandling from "../../AxiosErrorHandling/AxiosErrorHandling";
import SexOffenderCheckModal from "./SexOffenderCheckModal";
import customMethods from "../../customMethods";
import CustomModal from "../../customModal/customModal";
import LazyLoadImage from "../../LazyLoad/LazyLoad";
import { toast } from "react-toastify";

const SexOffender = () => {
  const [modalShow, setModalShow] = useState(false);
  const [customerDataModal, setCustomerDataModal] = useState(false);
  const [customerIndexing, setCustomerIndexing] = useState(null);

  const [step, setStep] = useState(1);

  const [customersData, setCustomersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [prevDisable, setPrevDisable] = useState("");
  const [nextDisable, setNextDisable] = useState("");
  const [tableRows, setTableRows] = useState(10);
  const [isLoadingHistory, setisLoadingHistory] = useState(true);

  const axios = useAxiosWithAuthErrorHandling();
  const { jwtToken } = useSelector((state) => state.userAuth);

  const fetchCustomerData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API_GET_CUSTOMER_HISTORY,
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.status === 200) {
        setCustomersData(response.data.data);
      }
    } catch (error) {
      toast.error("Error fetching customer data:");
    }
    setisLoadingHistory(false);
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  function handleTableRowChange(e) {
    e.preventDefault();
    const input = parseInt(e.target.value);
    setCurrentPage(1);
    setTableRows(input);
  }

  const indexOfLastItem = currentPage * tableRows;
  const indexOfFirstItem = indexOfLastItem - tableRows;

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredData = customersData.filter((customer) =>
    customer.name.toLowerCase().includes(searchQuery)
  );
  const totalRecords = filteredData.length;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const paginationLastIndex = currentItems.length;

  const paginate = (type) => {
    const totalPages = Math.ceil(filteredData.length / tableRows);

    let pageNumber;
    if (type === "next") {
      if (currentPage + 1 > totalPages) {
        setNextDisable(true);
        return;
      }
      pageNumber = currentPage + 1;
    } else {
      if (currentPage - 1 <= 0) {
        setPrevDisable(true);
        return;
      }
      pageNumber = currentPage - 1;
    }
    setCurrentPage(pageNumber);
  };

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="table-page-top">
          <h2>Sex Offender Check</h2>
          <button
            className="button-primary"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            New Check
          </button>
          <SexOffenderCheckModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setStep(1);
            }}
            step={step}
            setStep={setStep}
            setCustomersData={setCustomersData}
          />
        </div>

        <div className="table-wrapper">
          <InputGroup className="mb-3 serach-ontable">
            <InputGroup.Text id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>
          {!isLoadingHistory ? (
            customersData.length ? (
              <Table className="customerDetails table-hover" responsive="lg">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    {/* <th>Crime</th>
                                    <th>Conviction Date</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((customer, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        setCustomerIndexing(indexOfFirstItem + index);
                        setCustomerDataModal(true);
                      }}
                    >
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{customer.name}</td>
                      <td>
                        {customer.address ||
                          `${customer.state}, ${customer.zipcode}`.replace(
                            /^\w/,
                            (c) => c.toUpperCase()
                          )}
                      </td>
                      {/* <td>{customer.crime ? customer.crime : '---'}</td>
                                        <td>{customer.convictionDate ? customMethods.formatDate(customer.convictionDate) : '---'}</td> */}
                      <td>{customer.status ? "Passed" : "Failed"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="data-not-found">No Customers Found!</div>
            )
          ) : (
            <Spinner variant="primary" animation="border" />
          )}
          {filteredData.length > 5 && (
            <div className="pagination-btm-tablecustom">
              <div className="select-withbx">
                <p>Rows per page:</p>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => handleTableRowChange(e)}
                >
                  <option value="10">10</option>
                  <option value="5">05</option>
                </Form.Select>
              </div>

              <div className="select-withvalue">
                <p>
                  <span>{indexOfFirstItem + 1}</span>-
                  <span>{indexOfFirstItem + paginationLastIndex}</span>
                </p>
                <p>of</p>
                <p>{totalRecords}</p>
              </div>
              <div className="select-witharrow">
                <button>
                  <Image
                    disable={prevDisable}
                    onClick={() => paginate("prev")}
                    src={process.env.PUBLIC_URL + "/arrow-left-sm.svg"}
                  />
                </button>

                <button>
                  <Image
                    disable={nextDisable}
                    onClick={() => paginate("next")}
                    src={process.env.PUBLIC_URL + "/arrow-right-sm.svg"}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        onHide={() => {
          setCustomerDataModal(false);
        }}
        show={customerDataModal}
        heading={"Customer Details"}
      >
        {customerIndexing !== null && customersData && (
          <>
            <div className="pop-step-tabelcntnt">
              <Table
                className="single-customerDetails popup-table table-borderless offender-table"
                responsive="lg"
              >
                <tbody>
                  <tr className="popup-sex-offender-customer-header">
                    <LazyLoadImage
                      effect="blur"
                      alt="profile-img"
                      width={170}
                      height={200}
                      className="rounded"
                      src={
                        customersData[customerIndexing]["offenderImageUrl"] ||
                        process.env.PUBLIC_URL + "/profile-dummy.jpg"
                      }
                    />
                  </tr>
                  {Object.keys(customersData[customerIndexing]).map(
                    (key, index) => {
                      const value = customersData[customerIndexing][key];
                      if (
                        value !== null &&
                        value !== undefined &&
                        value !== "" &&
                        value !== "Unknown" &&
                        ![
                          "",
                          "firstNameNickNames",
                          "content",
                          "updatedAt",
                          "user",
                          "offenderImageUrl",
                          "status",
                          "userId",
                          "createdAt",
                          "id",
                          "lat",
                          "lng",
                        ].includes(key)
                      ) {
                        return (
                          <tr key={index}>
                            <th>
                              {key === "crime"
                                ? "Crime Details"
                                : key === "registrationDate"
                                ? "Registration"
                                : key === "offenderImageUrl"
                                ? "Avatar"
                                : key === "convictionDate"
                                ? "Conviction Date"
                                : key.charAt(0).toUpperCase() + key.slice(1)}
                            </th>

                            <td>
                              {[
                                "dob",
                                "registrationDate",
                                "convictionDate",
                              ].includes(key)
                                ? customMethods.formatDate(value)
                                : value}
                            </td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </CustomModal>
    </Wrapper>
  );
};

export default SexOffender;
