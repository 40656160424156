import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const initialState = {
  jwtToken: null,
  user: {},
  isOtpVerified: false,
  rememberMe: false,
  loading: "hold",
};

export const fetchUserByJWT = createAsyncThunk(
  "users/fetchByJWTStatus",
  async (jwtToken) => {
    const response = await axios.get(
      process.env.REACT_APP_API_GET_REFRESHED_USER,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    return response.data.user;
  }
);

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.jwtToken = action.payload.jwtToken;
      state.user = action.payload.user;
      state.isOtpVerified = action.payload.isOtpVerified;
      state.rememberMe = action.payload.rememberMe;
      state.adminId = action.payload.adminId;
      state.subscriptionExpired = action.payload.subscriptionExpired;
      state.subscriptionEndDate = action.payload.subscriptionEndDate;
      state.subscription = action.payload.subscription;
    },
    clearAuth: (state) => {
      state.jwtToken = null;
      state.user = {};
      state.isOtpVerified = false;
      state.rememberMe = false;
      state.adminId = null;
      state.subscriptionExpired = null;
      state.subscription = null;
      state.subscriptionEndDate = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByJWT.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchUserByJWT.fulfilled, (state, action) => {
        state.loading = "idle";
        state.user = action.payload;
        state.jwtToken = action.meta.arg;

        const decodedToken = jwtDecode(action.meta.arg);
        state.isOtpVerified = decodedToken.hasVerifiedOTP;
        state.rememberMe = decodedToken.rememberMe;
      })
      .addCase(fetchUserByJWT.rejected, (state, action) => {
        state.loading = "idle";
        clearAuth();
      });
  },
});

export const { setAuth, clearAuth, setRememberMe } = userAuthSlice.actions;

export default userAuthSlice.reducer;
