import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

const RewardModal = ({ show, onHide, step }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userAuth.user);
  const [guest, setGuest] = useState(null); // Stores fetched guest info
  const [showConfirm, setShowConfirm] = useState(false); // Shows confirmation screen
  const hostId = user.id;

  const [formData, setFormData] = useState({
    uid: "",
    email: "",
    firstName: "",
    lastName: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the UID field has value and disable/enable other fields accordingly
    if (name === "uid") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        email: value ? "" : prevState.email, // Clear email if UID has value
        firstName: value ? "" : prevState.firstName, // Clear firstName if UID has value
        lastName: value ? "" : prevState.lastName, // Clear lastName if UID has value
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_GET_All_GUEST
        );
        if (response.status === 200) {
          console.log("users info");
        } else {
          toast.error("Failed to fetch guests");
        }
      } catch (error) {
        toast.error("Error in fetching guests");
      }
    };

    fetchGuests();
  }, []);

  const handleUidSearch = async () => {
    if (!formData.uid) {
      toast.error("Please enter a UID");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GET_GUEST}${formData.uid}`
      );

      if (response.status === 200) {
        const guestData = response.data.user;
        setGuest(guestData);
        setFormData({
          email: guestData.email || "",
          firstName: guestData.firstName || "",
          lastName: guestData.lastName || "",
          message: formData.message,
        });
        setShowConfirm(true);
      }
    } catch (error) {
      if (error.response) {
        // Display the error message sent from the backend
        toast.error(error.response.data.message || "Error fetching guest");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const handleManualSubmit = () => {
    if (!formData.email || !formData.firstName || !formData.lastName) {
      toast.error("Please fill in all the required fields");
      return;
    }
    setShowConfirm(true);
  };

  const handleSubmitReward = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_CREATE_REWARD,
        { ...formData, hostId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Reward info stored successfully");
        const rewardId = response.data.reward.id;
        navigate(`/dashboard/reward-amount/${rewardId}`);
        onHide(); // Close modal after success
      }
    } catch (error) {
      if (error.response) {
        // Display the error message sent from the backend
        toast.error(error.response.data.message || "Failed to create reward");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Reward
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Step 1: UID or Manual Input */}
        {!showConfirm ? (
          <div>
            <p>
              If you're searching for UID, just enter the UID and ignore the
              rest of the fields.
            </p>
            <Form>
              {/* UID Input */}
              <Form.Group className="mb-3" controlId="uid">
                <Form.Label>UID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter UID"
                  name="uid"
                  value={formData.uid}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Form.Group>

              <Button
                variant="primary"
                onClick={handleUidSearch}
                className="mb-3"
              >
                Search by UID
              </Button>

              <hr />
              <p>Or manually add guest information</p>

              {/* Email Input */}
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!!formData.uid} // Disable if UID has value
                />
              </Form.Group>

              {/* First Name Input */}
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!!formData.uid} // Disable if UID has value
                />
              </Form.Group>

              {/* Last Name Input */}
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={!!formData.uid} // Disable if UID has value
                />
              </Form.Group>

              {/* Manual Submit Button */}
              <Button variant="primary" onClick={handleManualSubmit}>
                Submit Manually
              </Button>
            </Form>
          </div>
        ) : (
          // Step 2: Confirmation Screen
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {guest ? (
              <div>
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Is this the right guest?
                </p>
                <p>
                  <strong>Name:</strong> {guest.firstName} {guest.lastName}
                </p>
                <p>
                  <strong>Email:</strong> {guest.email}
                </p>
              </div>
            ) : (
              <div>
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Confirm the information:
                </p>
                <p>
                  <strong>Name:</strong> {formData.firstName}{" "}
                  {formData.lastName}
                </p>
                <p>
                  <strong>Email:</strong> {formData.email}
                </p>
              </div>
            )}
            {/* Add this inside the confirmation screen, just before the "Confirm and Proceed to Reward" button */}
            {showConfirm && (
              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter a message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Form.Group>
            )}

            <div style={{ marginTop: "20px" }}>
              <Button
                variant="primary"
                onClick={handleSubmitReward}
                style={{
                  marginRight: "10px",
                  padding: "10px 20px",
                  fontSize: "16px",
                }}
              >
                Confirm and Proceed to Reward
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setGuest(null); // Reset guest data when going back
                  setShowConfirm(false); // Return to the previous screen
                }}
                style={{ padding: "10px 20px", fontSize: "16px" }}
              >
                Go Back
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RewardModal;
