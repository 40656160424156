import React from 'react'
import Wrapper from '../Wrapper/Wrapper';
import './PaymentFailed.css'

const PaymentFailed = () => {
    return (
        <Wrapper>
            <div className="card-sucess red-failed">
            <div class="card">
                <div className="tick-machline" style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
                    <i class="checkmark">X</i>
                </div>
                <h1>Failed</h1>
                <p>Payment Failed!</p>
            </div>
            </div>
        </Wrapper>
    )
}

export default PaymentFailed;