import React from "react";
import { Link } from "react-router-dom";
import LazyLoadImage from "../../LazyLoad/LazyLoad";
const StaffDashboard = ({ credit, subscription ,subscriptionEndDate}) => {
  const humanReadableDate = new Date(subscriptionEndDate).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  return (
    <div className="dashboard-choice-wrapper">
      <div className="row justify-content-between align-items-center mb-4">
        <div className="col-auto">
          <div className="credit-balance">
            <p>
              Current Plan: <strong>{subscription}</strong>
            </p>
          </div>
        </div>
        <div className="col-auto">
          <div className="credit-balance">
            <p>
              Subscription Expire Date: <strong>{humanReadableDate}</strong>
            </p>
          </div>
        </div>
        <div className="col-auto">
          <div className="credit-balance">
            <p>
              Credit Balance: <strong>{credit}</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="dash-choose-op-wrp">
        <h2>What would you like to do today?</h2>
        <p>Choose menu you want to do today</p>

        <div className="dash-option-wrp">
          {/* <Link to="/dashboard/guest-review">
                <LazyLoadImage effect="blur" src={process.env.PUBLIC_URL + "/op-1.svg"} fluid={true.toString()} />
                Guest Review
            </Link> */}

          <Link to="/dashboard/rewards-overview">
            <LazyLoadImage
              effect="blur"
              src={process.env.PUBLIC_URL + "/op-2.svg"}
              fluid={true.toString()}
            />
            Rewards Overview
          </Link>

          <Link to="/dashboard/sex-offender">
            <LazyLoadImage
              effect="blur"
              src={process.env.PUBLIC_URL + "/op-3.svg"}
              fluid={true.toString()}
            />
            Sex Offender Check
          </Link>

          <Link to="/dashboard/purchase-credits">
            <LazyLoadImage
              effect="blur"
              src={process.env.PUBLIC_URL + "/op-5.svg"}
              fluid={true.toString()}
            />
            Purchase Credits
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StaffDashboard;
