import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { clearAuth } from '../../features/user/userAuth';

const PrivateGuestRoute = ({ Component }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {user, jwtToken} = useSelector(state => state.userAuth);
    const roleId = user.roleId;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!jwtToken) {
            navigate('/');
            return;
        }

        const decodedToken = jwtDecode(jwtToken);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp < currentTime) {
            dispatch(clearAuth());
            navigate('/');
            return;
        }
        if(roleId !== 2){
            navigate('/dashboard');
            return;
        }

    }, [location.pathname]);

    return <Component />;
};

export default PrivateGuestRoute;